export default {
    init() {
        // JavaScript to be fired on all pages

        // Jquery-match-height
        $(function() {
            $('.match-height-news-title').matchHeight();
            $('.match-height-news-block').matchHeight();
            $('.match-height-projects-block').matchHeight();
            $('.match-height-projects-content').matchHeight();
        });      

        // Slick slider example
        $('.slick__slider').slick({
            dots: false,
            infinite: true,
            fade: true,
            arrows: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {

                    },
                },
            ],
        });

        $(document).ready(function() {
            $('.fancybox').fancybox();
        });
        
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
